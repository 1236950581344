
import Vue, { VueConstructor } from "vue";
import { mapGetters} from "vuex"; // mapState

import FormMixins from "@/mixins/form";
import CrudMixins from "@/mixins/crud";
import AnalyticsMixins from "@/mixins/basisAnalysis";
import { IParams } from "@/types/store-types";

import  DisplayUsers  from "@/models/user";
import Growing from "@/models/growing";
import UserBasisCity from "@/models/userBasisCity";

import { userService } from "@/services/user";
import { AvailableBasisCities } from "@/business/BasisProjection";
import { basisProjectionService } from "@/services/basis_projection";


type Header = {
  text: string;
  value: string;
  align: string;
  sortable: boolean;
};


export default (
  Vue as VueConstructor<
    Vue &
    InstanceType<typeof CrudMixins> &
    InstanceType<typeof AnalyticsMixins> &
    InstanceType<typeof FormMixins>
  >
).extend({
  // components: {
  //   BasisCitiesForm: BasisCitiesForm,
  // },
  mixins: [CrudMixins, AnalyticsMixins, FormMixins],
  data: () => ({
    // LISTA DE USERS
    users: [] as Array<DisplayUsers>,
    // CIDADES
    basisCities: [] as AvailableBasisCities[],
    // CIDADES FILTRADAS
    filteredCities: [] as AvailableBasisCities[],
    // USER CITIES
    basisCitiesUser: [] as UserBasisCity[],
    // USER SELECIONADO
    selectedUser: new DisplayUsers(),
    //  CULTURA SELCIONADA
    selectedGrowing: new Growing(),
    // LOCAL LOADING
    localLoading: false,
    // CIDADES SELECIONADAS
    selectedCity: [] as AvailableBasisCities[],
    // PESQUISAR NA TABELA
    searchCity: '',
    // CONTROLA VIEW De CIDADES SELECIONADAS
    viewSelected: false,
    // CABEÇALHO TABELA
    headers: [
      {
        text: "Cidade",
        value: "city",
        align: "left",
        sortable: true,
      },
      {
        text: "Estado",
        value: "state",
        align: "left",
        sortable: true,
      },
      {
        text: "Cultura",
        value: "growing",
        align: "left",
        sortable: true,
      },
    ] as Array<Header>,
  }),

  async mounted() {
    this.localLoading = true;
    await this.initLoad();
    this.localLoading = false;
  },

  methods: {
    async initLoad(): Promise<void> {
      this.users = await userService.getNusers();
      this.basisCities = await basisProjectionService.getAllBasisCities()
      this.selectedGrowing = this.growings.find((el: Growing) => el.symbol ==='C')
      this.setCities()
    },
    setCities() {
      this.viewSelected = false;
      if (this.selectedGrowing) {
        this.filteredCities = this.basisCities.filter((el: AvailableBasisCities) => el.growing === this.selectedGrowing.nameEnglish)
      } else {
        this.filteredCities = this.basisCities
      }
    },
    filterAllowed() {
      if (this.selectedUser.id) {
        this.viewSelected = true;
        this.filteredCities = this.filteredCities.filter((el: AvailableBasisCities) => {
          const item = this.basisCitiesUser.find((bc) =>
            bc.city === el.city &&
            bc.state === el.state &&
            bc.growing.symbol === el.growing_symbol &&
            bc.user_id === this.selectedUser.id
          )
          if (item?.city === el.city ) return el
        })
      }
    },
    checkEqual(o1: any, o2: any){
      return Object.keys(o1).length === Object.keys(o2).length
      && Object.keys(o1).every(p => o1[p] === o2[p]);
    },
    async save(): Promise<void> {
      if (this.selectedUser.id && this.selectedCity.length && this.selectedGrowing?.id) {
        // CHECK IF ITEM ALREADY SAVED
        let alreadySave = this.basisCities.filter((bc) => {
          return this.basisCitiesUser.some((bcu) =>{
            return (
              bc.city === bcu.city &&
              bc.state === bcu.state &&
              bc.growing_symbol === bcu.growing.symbol
            )
          })
        }) || []

        // IF NOTHING TO SAVE WARNING USER
        if (this.checkEqual(this.selectedCity, alreadySave)){
          return this.$alertError('Items já salvo');
        }

        // IF CITY SELECTED NOT IN USER BASIS CITY, SAVE IT
        try {
          this.selectedCity.map(async (el) => {
            if (!alreadySave.length || !alreadySave.includes(el)) {
              await this.saveItem(el)
            }
          });
          this.$alertSuccess("Cidades salvas com sucesso!");
          this.getUserCities();
        } catch(error) {
          this.$alertError("Erro ao salvar cidades!");
        }

        let toRemove = this.basisCitiesUser.filter((bcu) => {
          return !this.selectedCity.some((bc) =>{
            return (
              bc.city === bcu.city &&
              bc.state === bcu.state &&
              bc.growing_symbol === bcu.growing.symbol
            )
          })
        })
        if (toRemove) {
          toRemove.map((el) => {
            this.deleteItem(el)
            this.$alertSuccess("Deletado com sucesso!");
          })
        }
      } else {
        if (!this.selectedUser.id) {
          return this.$alertError('Nenhum usuário selecionado');
        } else if (!this.selectedGrowing || !this.selectedGrowing?.id) {
          return this.$alertError('Nenhuma cultura selecionado');
        } else if (!this.selectedCity.length) {
          return this.$alertError('Nenhuma cidade selecionado');
        } else {
          return this.$alertError('erro inesperado');
        }
      }
    },

    async saveItem(item: AvailableBasisCities): Promise<void> {
      const newObj = new UserBasisCity();
      newObj.city = item.city
      newObj.state = item.state

      if (this.selectedUser.id) {
        newObj.user_id = this.selectedUser.id
      }

      if (this.selectedGrowing.id) {
        newObj.growing_id = this.selectedGrowing.id
      }
      try{
        this.setResource('user_basis_city')
        const response = await this.saveResource(newObj);
        await this.updateUserBasisCitiesStore(response);
      } catch(error) {
        console.log(error)
      }
    },

    async deleteItem(item: UserBasisCity) {
      try {
        this.setResource("user_basis_city");
        await this.deleteUserBasisCities(item.id);
        this.deleteUserBasisCitiesStore(item);
      } catch(error) {
        this.$alertError("Houve um problema ao deletar");
      }
    },

    async changeUser() {
      await this.getUserCities();
      if (this.selectedUser.id) {
        this.selectedCity = this.basisCities.filter((bc) => {
          return this.basisCitiesUser.some((bcu) =>{
            return (
              bc.city === bcu.city &&
              bc.state === bcu.state &&
              bc.growing_symbol === bcu.growing.symbol
            )
          })
        })
      }
    },

    // GET USER'S DATA
    async getUserCities(): Promise<void> {
      this.localLoading = true;
      const params: IParams = {
        user_id: this.selectedUser?.id,
        growing_id: this.selectedGrowing?.id,
      };
      try {
        await this.getUserBasisCities(params);
        this.basisCitiesUser = this.userBasisCities;
      } catch (error) {
        this.$alertError("Erro ao carregar os embarques");
        console.log(error);
      } finally {
        this.localLoading = false;
      }
    },


  },
  computed: {
    ...mapGetters({
      growings: "getGrowings",
      user: "getLoggedUser",
    }),

    growingsAvailable(): Array<Growing> {
      return this.growings.filter((el: Growing) => el.symbol == "C" || el.symbol == "S");
    },
    DisplayUsers(): DisplayUsers[] {
      return this.users;
    },
  },
  watch: {
    selectedGrowing() {
      this.setCities();
      this.changeUser();
    },
    selectedUser() {
      this.setCities();
      this.changeUser();
    }
  }
});
